import React from 'react';
import HeroSection from './HeroSection';
import Table from './Table';
import './Table.css'



const Home = () => {
    return (
        <>
        < HeroSection/>
        <Table />
        </>
    )
}

export default Home