import { useState, useEffect } from "react";
import { storage } from "./firebase-config";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import "./Gallery.css";

const Gallery = () => {

    // State to store uploaded file
   const [imageUrls, setImageUrls] = useState([]);

   const imagesListRef = ref(storage, '/files');
 
   useEffect(() => {
     listAll(imagesListRef).then((response) => {
       response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
            setImageUrls((prev) => [...prev, url]);
          console.log(url)
        });
      });
    });
  }, []);

  const [model, setModel] = useState(false)
  const [tempUrl, setTempUrl] = useState(' ')

  const getImg = (url) => {
    setTempUrl(url)
    setModel(true);
  }


   return (
      <>
      <div className={model? 'model open' : 'model'}>
        <img src={tempUrl} alt="" />
        <button className="xmark-button" onClick={()=> setModel(false) }><i class="fa-solid fa-xmark"></i></button>
      </div>
       <div className="gallery">
           {imageUrls.map((url, index) => {
                 return (
                  <div className="pictures" key={index} onClick={()=> getImg(url)}>
                    <img src={url} alt='' />
                  </div> 
                 )
             })} 
        </div>
    </>
   );
            }


export default Gallery
