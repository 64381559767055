import React from 'react'
import './HeroSection.css'


const HeroSection = () => {
  return (
    <div className='hero-container'>
      
        
    </div>
    
  )
}

export default HeroSection









