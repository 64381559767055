import React from "react";
import Navbar from "./Components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Clubs from "./Components/Clubs";
import Fixtures from "./Components/Fixtures";
import Gallery from "./Components/Gallery";
import News from "./Components/News";
import Results from "./Components/Results";
import FooterSection from "./Components/Footer";
import { AuthContextProvider } from "./Components/context/AuthContext";
import ProtectedRoute from "./Components/ProtectedRoute";
import Signin from "./Components/Signin";
import Admin from "./Components/Admin";
import './Components/Table.css'



const App = () => {

  return (
    <>
    <div className="">
      <AuthContextProvider>
        <Router>
        <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/clubs" element={<Clubs />} />
            <Route path="/fixtures" element={<Fixtures />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/news" element={<News />} />
            <Route path="/results" element={<Results />} />
            <Route path="/signin" element={<Signin />} />
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <Admin />
                </ProtectedRoute>
              }
            />
          </Routes>
          <FooterSection />
           </Router>
      </AuthContextProvider>
      </div>
    </>
    
  );
};

export default App;
