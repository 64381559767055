import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import "./Clubs.css";
import { db } from "./firebase-config";
import { storage } from "./firebase-config";
import { ref, getDownloadURL, listAll } from "firebase/storage";


const Clubs = () => {
    const clubsCollectionRef = collection(db, "clubs");

    const [clubs, setClubs] = useState([]);

    // State to store uploaded files
    const [logo, setLogo] = useState('')
    const [imageLinks, setImageLinks] = useState([])

    const logoListRef = ref(storage, '/logos');

    useEffect(()=> {
        listAll(logoListRef).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageLinks((prev) => [...prev, url]);
                    console.log(url)
                });
            });
        });
    }, []);

      useEffect(() => {
        const getClubs = async () => {
          const data = await getDocs(clubsCollectionRef)
          
          // console.log(data);
          setClubs(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getClubs();
      }, []);
    

  return (
    <section className="section-club">
      <div className="is-centered">
        {imageLinks.map((url, index) =>{

            return (
                <>
                <img className="club-images" src={url} alt='' key={index} />
                </>
            ) 
        })}
      </div>

      <div className="club-card">
        {clubs.map((club, index) => {
            return (
                <div className='' key={index}>
                    <h1>{club.clubName}</h1>
                    <p>{club.clubInfo}</p>
               
                </div> 
            )})}   
      </div>
    </section>
  );
        
};

export default Clubs;


